import React from 'react';
import {BsLinkedin, BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { BiLogoGmail } from "react-icons/bi";

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://www.linkedin.com/in/marchitanstefan/" target="_blank" rel="noopener noreferrer">
      <BsLinkedin />
    </a>
    <a href="mailto:stefan.marchitan@gmail.com" target="_blank" rel="noopener noreferrer">
      <BiLogoGmail />
    </a>
    <div>
      <BsTwitter />
    </div>
    <div>
      <FaFacebookF />
    </div>
    <div>
      <BsInstagram />
    </div>
  </div>
);

export default SocialMedia;
