import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  // Obiect pentru maparea între denumirile din lista de navigare și denumirile afișate în meniu
  const menuItems = {
    home: 'Home',
    about: 'About',
    work: 'Portfolio',
    skills: 'Skills',
    contact: 'Contact',
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
       
      </div>
      <ul className="app__navbar-links">
        {Object.keys(menuItems).map((key) => (
          <li className="app__flex p-text" key={`link-${key}`}>
            <div />
            <a href={`#${key}`}>{menuItems[key]}</a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {Object.keys(menuItems).map((key) => (
                <li key={key}>
                  <a href={`#${key}`} onClick={() => setToggle(false)}>
                    {menuItems[key]}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
